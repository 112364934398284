<div class="y3-margin-top-24 y3-padding-left-16 y3-padding-right-16" *ngIf="mrrReponse">

  <table>
    <tr>
      <th>TOTAL MRR</th>
      <th>TOTAL MRR HT</th>
      <th>STRIPE UNPAID </th>
      <th>CUSTOMERS TRIAL</th>
      <th>PROPERTIES TRIAL</th>
      <th>TOTAL CUSTOMERS</th>
      <th>TOTAL PROPERTIES</th>
      <th>AVG MRR HT PER PROPERTY</th>
    </tr>
    <tr style="font-size: 20px; font-weight: bold; color: darkseagreen">
      <td>{{mrrReponse.totalMrr.toFixed(2) | currency: "EUR"}}</td>
      <td>{{mrrReponse.totalMrrHT.toFixed(2) | currency: "EUR"}}</td>
      <td style="background: #fe3945; color: white">{{mrrReponse.totalPendingInvoice.toFixed(2) | currency: "EUR"}}</td>
      <td style=" color: indianred">{{mrrReponse.nbCustomerInTrial }}</td>
      <td style=" color: indianred">{{mrrReponse.totalPropertiesInTrial }}</td>
      <td>{{mrrReponse.nbCustomer }}</td>
      <td>{{mrrReponse.totalProperties }}</td>
      <td>{{(mrrReponse.totalMrrHT / mrrReponse.totalProperties ).toFixed(2) | currency: "EUR"}}</td>
    </tr>
  </table>

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">COUNTRY MRR</div>
  <table style="margin-top: 8px;">
    <tr>
      <th style="text-align: left">COUNTRY</th>
      <th>TOTAL MRR</th>
      <th>TOTAL MRR HT</th>
      <th>CUSTOMERS TRIAL</th>
      <!--<th>PROPERTIES TRIAL</th>-->
      <th>TOTAL CUSTOMERS</th>
      <th>TOTAL PROPERTIES</th>
      <th>AVG CUSTOMER BASKET HT</th>
    </tr>
    <tr *ngFor="let mrrCountry of mrrReponse?.countryMrr; let i = index" >
      <td style="text-align: left">{{mrrCountry.country}}</td>
      <td>{{mrrCountry.totalMrr.toFixed(2) | currency: "EUR"}}</td>
      <td>{{mrrCountry.totalMrrHT.toFixed(2) | currency: "EUR"}}</td>
      <td style=" color: indianred">{{mrrCountry.nbCustomerInTrial }}</td>
      <!--<td style=" color: indianred">{{mrrCountry.totalPropertiesInTrial }}</td>-->
      <td>{{mrrCountry.nbCustomer }}</td>
      <td>{{mrrCountry.totalProperties }}</td>
      <td>{{mrrCountry.totalAvgCustomer.toFixed(2) | currency: "EUR"}}</td>
    </tr>
  </table>


  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">CUSTOMER SEGMENTATION</div>
  <table style="margin-top: 8px;">
    <tr>
      <th style="text-align: left">SEGMENT NAME</th>
      <th>NB CUSTOMERS</th>
      <th>NB PROPERTIES</th>
      <th>AVG PRICE HT</th>
      <th>TOTAL MRR HT</th>
      <th>% MRR HT</th>
    </tr>
    <tr *ngFor="let mrrCustomer of mrrReponse?.customerSegmentation; let i = index" >
      <td style="text-align: left">{{mrrCustomer.displayThings}}</td>
      <td>{{mrrCustomer.totalCustomers }}</td>
      <td>{{mrrCustomer.totalProperties }}</td>
      <td>{{(mrrCustomer.mrrHT / mrrCustomer.totalProperties).toFixed(2) }}</td>
      <td>{{mrrCustomer.mrrHT.toFixed(2) | currency: "EUR"}}</td>
      <td >{{((mrrCustomer.mrrHT / mrrReponse.totalMrrHT) * 100).toFixed(2)}} %</td>
    </tr>

    <tr>
      <td style="text-align: left" class="y3-bold">TOTAL</td>
      <td class="y3-bold">{{calculateTotalCustomerSegment()}}</td>
      <td class="y3-bold">{{calculateTotalPropertiesSegment()}}</td>
      <td class="y3-bold">{{(calculateTotalMrrSegment() / calculateTotalPropertiesSegment()).toFixed(2)}}</td>
      <td class="y3-bold">{{calculateTotalMrrSegment().toFixed(2) | currency: "EUR"}}</td>
      <td class="y3-bold"></td>
    </tr>

  </table>


  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">PARTNER RETRO COMMISSIONS</div>
  <table style="margin-top: 8px;">
    <tr>
      <th style="text-align: left">PARTNER NAME</th>
      <th>NB CUSTOMERS</th>
      <th>TOTAL MRR HT</th>
      <th>COMISSIONS HT</th>
    </tr>
    <tr *ngFor="let mrrPartner of mrrReponse?.partnerRetroCommissions; let i = index" >
      <td style="text-align: left">{{mrrPartner.partnerName}}</td>
      <td>{{mrrPartner.nbCustomer }}</td>
      <td>{{mrrPartner.mrrHT.toFixed(2) | currency: "EUR"}}</td>
      <td>{{mrrPartner.commissionsHT.toFixed(2) | currency: "EUR"}}</td>
    </tr>
    <tr>
      <td style="text-align: left" class="y3-bold">TOTAL</td>
      <td class="y3-bold">{{calculateTotalCustomerPartner()}}</td>

      <td></td>
      <td class="y3-bold">{{calculateTotalPartner().toFixed(2) | currency: "EUR"}}</td>
    </tr>
  </table>


  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: red">UNPAID INVOICES</div>
  <table style="margin-top: 8px; color: red">
    <tr>
      <th>Index</th>
      <th style="text-align: left;">Email</th>
      <th style="text-align: center;">Name</th>
      <th style="text-align: center;">Phone</th>
      <th style="text-align: center;">Date</th>
      <th style="text-align: center;">Amount</th>
    </tr>

    <tr *ngFor="let invoiceItem of mrrReponse?.adminPendingInvoice?.invoices; let i = index" >
      <td style="width: 50px">{{i + 1}}</td>
      <td style="text-align: left;">{{invoiceItem.customerEmail}}</td>
      <td style="text-align: left;">{{invoiceItem.customerName}}</td>
      <td style="text-align: left;">{{invoiceItem.customerPhone}}</td>
      <td style="text-align: center;">{{invoiceItem.invoiceDate}}</td>
      <td>{{invoiceItem.amount.toFixed(2) | currency: "EUR"}}</td>
    </tr>
  </table>


  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: darkred">TRIAL PERIOD CUSTOMERS</div>
  <table style="margin-top: 8px; color: darkred">
    <tr>
      <th>Index</th>
      <th style="text-align: left;">Email</th>
      <th style="text-align: left;">Area</th>
      <th style="text-align: left;">Launch</th>
      <th style="text-align: center;">LastTime Viewed</th>
      <th style="text-align: center;">Days before EndOfTrial</th>
      <th style="text-align: center;">Paiement Method</th>
      <th style="text-align: center;">Stripe Promotion Code</th>
      <th>Properties</th>
    </tr>

    <tr *ngFor="let mrrCustomer of mrrReponse?.customerMrrsInTrial; let i = index" >
      <td style="width: 50px">{{i + 1}}</td>
      <td style="text-align: left;">{{mrrCustomer.customerEmail}}</td>
      <td style="text-align: left;">{{mrrCustomer.areaOfBusiness}}</td>
      <td style="text-align: left;">{{mrrCustomer.brevoEmailLaunchProcess}}</td>
      <td style="text-align: center;">{{utilService.formatDateTime(mrrCustomer.lastTimeViewed)}} - {{utilService.difDays(mrrCustomer.lastTimeViewed)}} Days</td>
      <td style="text-align: center;">{{mrrCustomer.daysBeforeEndOfTrial}}</td>
      <td style="text-align: center;" *ngIf="mrrCustomer.hasPaimentMethod">
        <mat-icon>check_circle</mat-icon>
      </td>
      <td *ngIf="!mrrCustomer.hasPaimentMethod"></td>
      <td style="text-align: center;"> {{mrrCustomer.stripePromotionCode}}</td>
      <td style="text-align: right;">{{mrrCustomer.totalProperties}}</td>
    </tr>
  </table>


  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: steelblue">CONVERTED CUSTOMERS (LAST 14 DAYS)</div>
  <table style="margin-top: 8px; color: steelblue">

    <tr>
      <th>Index</th>
      <th style="text-align: left;">Email</th>
      <th style="text-align: left;">Area</th>
      <th style="text-align: left;">Launch</th>
      <th style="text-align: center;">LastTime Viewed</th>
      <th style="text-align: center;">Days after EndOfTrial</th>
      <th>Properties</th>
      <th >% MRR</th>
    </tr>

    <tr *ngFor="let mrrCustomer of mrrReponse?.customerConvertedLast14Days; let i = index" >
      <td style="width: 50px">{{i + 1}}</td>
      <td style="text-align: left;">{{mrrCustomer.customerEmail}}</td>
      <td style="text-align: left;">{{mrrCustomer.areaOfBusiness}}</td>
      <td style="text-align: left;">{{mrrCustomer.brevoEmailLaunchProcess}}</td>
      <td style="text-align: center;">{{utilService.formatDateTime(mrrCustomer.lastTimeViewed)}} - {{utilService.difDays(mrrCustomer.lastTimeViewed)}} Days</td>
      <td style="text-align: center;">{{mrrCustomer.daysBeforeEndOfTrial}}</td>
      <td style="text-align: right;">{{mrrCustomer.totalProperties}}</td>
      <td >{{((mrrCustomer.mrrHT / mrrReponse.totalMrrHT) * 100).toFixed(2)}} %</td>
    </tr>
  </table>

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: red">LOST PROSPECTS (LAST 21 DAYS)</div>
  <table style="margin-top: 8px; color: red">

    <tr>
      <th>Index</th>
      <th style="text-align: left;">Email</th>
      <th style="text-align: left;">Area</th>
      <th style="text-align: left;">Launch</th>
      <th style="text-align: center;">LastTime Viewed</th>
      <th style="text-align: center;">Days before EndOfTrial</th>
      <th>Properties</th>
    </tr>

    <tr *ngFor="let mrrCustomer of mrrReponse?.customerTrialOverLast21Days; let i = index" >
      <td style="width: 50px">{{i + 1}}</td>
      <td style="text-align: left;">{{mrrCustomer.customerEmail}}</td>
      <td style="text-align: left;">{{mrrCustomer.areaOfBusiness}}</td>
      <td style="text-align: left;">{{mrrCustomer.brevoEmailLaunchProcess}}</td>
      <td style="text-align: center;">{{utilService.formatDateTime(mrrCustomer.lastTimeViewed)}} - {{utilService.difDays(mrrCustomer.lastTimeViewed)}} Days</td>
      <td style="text-align: center;">{{mrrCustomer.daysBeforeEndOfTrial}}</td>
      <td style="text-align: right;">{{mrrCustomer.totalProperties}}</td>
    </tr>
  </table>

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: red">CLOSED CUSTOMERS (LAST 21 DAYS)</div>
  <table style="margin-top: 8px; color: red">

    <tr>
      <th>Index</th>
      <th style="text-align: left;">Email</th>
      <th style="text-align: left;">Area</th>
      <th style="text-align: left;">Launch</th>
      <th style="text-align: center;">LastTime Viewed</th>
      <th style="text-align: center;">Days before EndOfTrial</th>
      <th>Properties</th>
      <th >% MRR</th>
    </tr>

    <tr *ngFor="let mrrCustomer of mrrReponse?.customerClosedOverLast21Days; let i = index" >
      <td style="width: 50px">{{i + 1}}</td>
      <td style="text-align: left;">{{mrrCustomer.customerEmail}}</td>
      <td style="text-align: left;">{{mrrCustomer.areaOfBusiness}}</td>
      <td style="text-align: left;">{{mrrCustomer.brevoEmailLaunchProcess}}</td>
      <td style="text-align: center;">{{utilService.formatDateTime(mrrCustomer.lastTimeViewed)}} - {{utilService.difDays(mrrCustomer.lastTimeViewed)}} Days</td>
      <td style="text-align: center;">{{mrrCustomer.daysBeforeEndOfTrial}}</td>
      <td style="text-align: right;">{{mrrCustomer.totalProperties}}</td>
      <td >{{((mrrCustomer.mrrHT / mrrReponse.totalMrrHT) * 100).toFixed(2)}} %</td>
    </tr>
  </table>




  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: darkorange">CUSTOMERS HAVING 80%+ PAUSED PROPERTIES</div>
  <table style="margin-top: 8px; color: darkorange">
    <tr>
      <th>Index</th>
      <th style="text-align: left;">Email</th>
      <th style="text-align: left;">Area</th>
      <th style="text-align: left;">Launch</th>
      <th style="text-align: center;">LastTime Viewed</th>
      <th style="text-align: center;">Paid vs Paused</th>
    </tr>

    <tr *ngFor="let mrrCustomer of mrrReponse?.customerInWarning; let i = index" >
      <td style="width: 50px">{{i + 1}}</td>
      <td style="text-align: left;">{{mrrCustomer.customerEmail}}</td>
      <td style="text-align: left;">{{mrrCustomer.areaOfBusiness}}</td>
      <td style="text-align: left;">{{mrrCustomer.brevoEmailLaunchProcess}}</td>
      <td style="text-align: center;">{{utilService.formatDateTime(mrrCustomer.lastTimeViewed)}} - {{utilService.difDays(mrrCustomer.lastTimeViewed)}} Days</td>
      <td style="text-align: center;">{{mrrCustomer.displayThings}}</td>
    </tr>
  </table>

  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">CUSTOMERS</div>
  <table style="margin-top: 8px;">
    <tr>
      <th>Index</th>
      <th style="text-align: left;">Email</th>
      <th style="text-align: left;">Area</th>
      <th style="text-align: left;">Launch</th>
      <th>Active Properties</th>
      <th>Avg Revenue Per Property</th>
      <th>Mrr</th>
    </tr>

    <tr *ngFor="let mrrCustomer of mrrReponse?.customerMrrs; let i = index" >
      <td style="width: 50px">{{i + 1}}</td>
      <td style="text-align: left;">{{mrrCustomer.customerEmail}}</td>
      <td style="text-align: left;">{{mrrCustomer.areaOfBusiness}}</td>
      <td style="text-align: left;">{{mrrCustomer.brevoEmailLaunchProcess}}</td>
      <td style="text-align: right;">{{mrrCustomer.totalProperties}}</td>
      <td>{{mrrCustomer.avgRevenuPerProperty.toFixed(2) | currency: "EUR"}}</td>
      <td>{{mrrCustomer.mrr.toFixed(2) | currency: "EUR"}}</td>
    </tr>
  </table>


  <div style="margin-top: 24px; font-size: 14px; font-weight: bold; color: black">CUSTOMERS STATISTICS</div>
  <table  style="margin-top: 8px;">
    <tr>
      <th>Month</th>
      <th>Paid Clients</th>
      <th>Premium</th>
      <th>Performance</th>
      <th>Success</th>
      <th>Closed</th>
      <th>Active Users</th>
      <th>Delta Active Users</th>
      <th>Active YaagoTeam</th>
      <th>Delta Active YaagoTeam</th>
      <th>Paid Properties</th>
      <th>Delta Properties</th>
      <th>Mrr TTC</th>
      <th>Mrr HT</th>
      <th>Delta Mrr</th>
    </tr>

    <tr *ngFor="let perf of yaagoPerformances; let i = index" >
        <td>{{perf.yaagoMonthlyLocalDate}}</td>
        <td>{{perf.numberOfPaidUsers}}</td>
        <td>{{perf.numberOfPaidUsersPremium}}</td>
        <td>{{perf.numberOfPaidUsersPerformance}}</td>
        <td>{{perf.numberOfPaidUsersSuccess}}</td>
        <td>{{perf.numberOfClosedUsers}}</td>
        <td>{{perf.numberOfActiveUsers}}</td>
        <td ngClass="{{getClass(calcActiveUSerPercentage(i))}}">{{calcActiveUSerPercentage(i)}}</td>
        <td>{{perf.numberOfActiveYaagoTeamUsers}}</td>
        <td ngClass="{{getClass(calcActiveUSerYaagoTeamPercentage(i))}}">{{calcActiveUSerYaagoTeamPercentage(i)}}</td>
        <td >{{perf.numberOfPaidProperties}}</td>
        <td ngClass="{{getClass(calcPropertyPercentage(i))}}">{{calcPropertyPercentage(i)}}</td>
        <td>{{perf.mrr.toFixed(2) | currency: "EUR"}}</td>
        <td><span *ngIf="perf.mrrHT">{{perf.mrrHT.toFixed(2) | currency: "EUR"}}</span></td>
        <td ngClass="{{getClass(calcPerfPercentage(i))}}">{{calcPerfPercentage(i)}}</td>
      </tr>

    </table>

</div>

